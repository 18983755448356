const ROUTES = [
  { name: "Home", path: "/home", icon: "diamond" },


  {
    name: "Cars Management",
    path: "/car-management",
    icon: "pen-to-square-regular",
  },
  {
    name: "Products Management",
    path: "/products-management",
    icon: "pen-to-square-regular",
  },
  {
    name: "Orders",
    icon: "cart-shopping-regular",
    path: "/orders",
  },
  {
    name: "Banners",
    path: "/banners",
    icon: "barcode",
  },

  {
    name: "Reviews",
    icon: "star-half-stroke-solid",
    path: "/reviews",
  },
  {
    name: "Customers",
    icon: "chart-user-regular",
    path: "/customers",
  },
  {
    name: "Employee",
    icon: "chart-user-regular",
    path: "/employ-users",
  },
  {
    name: "Statistics",
    icon: "chart-simple-regular",
    path: "/statistics",
  },
  {
    name: "Transactions",
    icon: "money-check-dollar-pen-regular",
    path: "/transactions",
  },
  {
    name: "Discounts ",
    path: "/discounts",
    icon: "money-check-dollar-pen-regular",
      // Using the "percent" icon for discounts
  },
  {
    name: "Coupons ",
    path: "/coupons",
    icon: "money-check-dollar-pen-regular",
      // Using the "percent" icon for discounts
  },
];


export default ROUTES;











  // {
  //   name: "Branches",
  //   path: "/sellers-list",
  //   icon: "rectangle-history-circle-user-regular",
  // },
  // {
  //   name: "Top Products",
  //   path: "/top-products",
  //   icon: "boxes-stacked-regular",
  // },

// {
  //   name: "Categories",
  //   icon: "layer-group-regular",
  //   path: "/categories",
  // },
  // {
  //   name: "Employee Roles",
  //   icon: "chart-user-regular",
  //   path: "/EmployRolesPage",
  // },
  // {
  //   name: "Reports",
  //   icon: "boxes-stacked-regular",
  //   path: "#",
  // },
  // {
  //   name: "Settings",
  //   icon: "gear-regular",
  //   path: "/general-settings",
  // },

  // {
  //   name: "Dashboard",
  //   icon: "rectangle-history-circle-user-regular",
  //   links: [
  //     { name: "Sales Analytics", path: "/" },
  //     { name: "Sellers List", path: "/sellers-list" },
  //     { name: "Sellers Table", path: "/sellers-table" },
  //     { name: "Sellers Grid", path: "/sellers-grid" },

  //     { name: "Revenue by Period", path: "/revenue-by-period" },
  //   ],
  // },
  // {
  //   name: "Products",
  //   icon: "boxes-stacked-regular",
  //   links: [
  //     { name: "Top Products", path: "/top-products" },
  //     { name: "Products Grid", path: "/products-grid" },
  //     { name: "Products Management", path: "/products-management" },
  //     { name: "Product Editor", path: "/product-editor" },
  //     { name: "Banners", path: "/banners" },
  //   ],
  // },
  // {
  //   name: "Orders",
  //   icon: "cart-shopping-regular",
  //   path: "/orders",
  // },

  // {
  //   name: "Pages",
  //   icon: "layer-group-regular",
  //   links: [
  //     { name: "Login", path: "/" },
  //     { name: "Page 404", path: "/404" },
  //   ],
  // },
  // {
  //   name: "Settings",
  //   icon: "gear-regular",
  //   path: "/general-settings",
  // links: [
  //   { name: "General Settings", path: "/general-settings" },
  //   { name: "Connected Apps", path: "/connected-apps" },
  // ],
  // },